.footerMain p {
  font-size: 10px;
  color: #fff;
}

.footerMain a {
  color: #fff;
}

.footerMain a:hover {
  color: #875c1b;
}
